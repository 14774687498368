export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST"
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS"
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL"

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST"
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS"
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL"

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST"
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS"
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL"