export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST"
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS"
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL"

export const ORDER_REQUEST = "ORDER_REQUEST"
export const ORDER_SUCCESS = "ORDER_SUCCESS"
export const ORDER_FAIL = "ORDER_FAIL"

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST"
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS"
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL"